import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Paper } from '@material-ui/core';

import withRoot from '../withRoot';
import Layout from '../components/Layout';
import IntroText from '../components/IntroText';
import Meta from '../components/Meta';
import LinkButton from '../components/LinkButton';

import { Link } from "gatsby";

const styles = theme => ({
  headerText: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px auto 20px',
  },
  sectionLinks: {
    maxWidth: '550px',
    margin: '0 auto 10px',
  },
  sectionLinksLast: {
    maxWidth: '550px',
    margin: '0 auto 40px',
  },
  viewAll: {
    margin: '0 auto 10px',
    flexDirection: 'column'
  },
  /* Style LinkButton block style here to keep them as a common component */
  linkButtonBlock: {
    display: 'block',
    margin: '10px 8px',
  },
  link: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '14px',
    '&:last-child' : {
      marginBottom: '0',
    }
  }
});

const Index = ({ classes, className, location }) => (
  <Layout location={location}>
    <Meta location={location} />
    <IntroText />

    <Grid container justify="center" alignItems="center">
      <h2 className={classNames(classes.headerText, className)}>Which issues are most important to you?</h2>
    </Grid>

    {/* @TODO - Make these buttons auto generate for all pages */}
    <Grid container justify="center" alignItems="center" className={classNames(classes.sectionLinks, className)}>
      <Grid item xs={12} sm={6}>
        <LinkButton to="/daily-life" className={classNames(classes.linkButtonBlock, className)}>Daily life</LinkButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LinkButton to="/economy" className={classNames(classes.linkButtonBlock, className)}>Economy</LinkButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LinkButton to="/immigration-and-borders" className={classNames(classes.linkButtonBlock, className)}>Immigration &amp; Borders</LinkButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LinkButton to="/sovereignty" className={classNames(classes.linkButtonBlock, className)}>Sovereignty</LinkButton>
      </Grid>
    </Grid>

    <Grid container justify="center" alignItems="center" className={classNames(classes.viewAll, className)}>
      <Link to="/view-all" className={classNames(classes.link, className)}>View All Issues</Link>
    </Grid>

    <Grid container justify="center" alignItems="center" className={classNames(classes.sectionLinks, classes.sectionLinksLast, className)}>
      <Grid item xs={12} sm={6}>
        <LinkButton to="/labour" className={classNames(classes.linkButtonBlock, className)}>Labour’s Tests</LinkButton>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LinkButton to="/leave-promises" className={classNames(classes.linkButtonBlock, className)}>Leave promises</LinkButton>
      </Grid>
    </Grid>

  </Layout>
);

Index.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Index.defaultProps = {
  data: {},
};

export default withRoot(withStyles(styles)(Index));
